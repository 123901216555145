.errorMain {
  background-color: #3B55D6;
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  font-family: sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errorButton {
  font-weight: 300;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid #efefef;
  padding: .5em;
  border-radius: 3px;
  transition: all .3s linear;
  margin-top: 1em; /* Ajuste a margem superior conforme necessário */
}

.errorButton:hover {
  background-color: #007aff;
  color: #fff;
}

.messageError {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

.statusError {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
  margin-top: -10%;
}

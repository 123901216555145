.logo-cisp1{
    z-index: 2;
    padding-top: 15px;
    padding-left: 30px;

    @media (max-width: 595px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }
}
.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 5;

  color: #fff;
  text-shadow: 1px 1px 1px #000;

  .title-group {
    display: flex;
    flex-direction: row;

  }
}

@media (min-width: 631px) {
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 5;

    color: #fff;
    text-shadow: 1px 1px 1px #000;

    h1 {
      font-size: 70px;
    }

    h2 {
      font-size: 25px;
      max-width: 580px;
      text-align: center;
    }

    .title-group {
      display: flex;
      flex-direction: row;

    }
  }
}

@media (min-width: 2400px) {
  .title {

    h1 {
      font-size: 83px;
    }

    h2 {
      font-size: 33px;
      max-width: 700px;
    }
  }
}

@media (max-width: 630px) {
  .title {
    padding: 0px 12px;

    h1 {
      font-size: 50px;
    }

    h2 {
      font-size: 21px;
    }
  }
}
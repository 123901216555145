.menu-link {
  text-decoration: none;
  color: white;
}

@media (min-width: 1024px) {
  .navbar {
    position: absolute;
    width: 100%;
    height: 70px;
    z-index: 6;
    color: white;

    .header-top-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      height: 70px;
      padding-top: 15px;
      padding-bottom: 50px;

      .header-top-frame {
        display: flex;
        align-items: center;

        .text-menu {
          display: flex;
          align-items: center;
          gap: 12px;
          cursor: pointer;
        }

        .hamb-menu {
          display: none;
        }

        &:first-child {
          padding-left: 30px;
        }

        &:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          position: relative;
          font-size: 28.5px;
        }
      }
    }

    .header-container {
      display: flex;
      justify-content: center;
      gap: 100px;
      flex-wrap: wrap;

      .header-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        font-size: 24px;
        cursor: pointer;

        &:first-child {
          grid-column: 2;
        }

        img {
          border-radius: 7px;
          height: 200px;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 6;
    background-color: rgba($color: #292929, $alpha: 0.90);
  }
  .navbar {
    position: absolute;
    width: 100%;
    height: 70px;
    z-index: 6;
    color: white;

    .header-top-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      direction: rtl;
      height: 70px;
      padding-top: 8px;
      padding-bottom: 20px;

      .header-top-frame {
        display: flex;
        align-items: center;

        &:first-child {
          padding-right: 25px;
          padding-top: 5px;
        }

        &:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-left: 30px;
        }

        span {
          position: relative;
          cursor: pointer;
          font-size: 28.5px;

          .span-arrow {
            position: absolute;
            padding-left: 10px;
            padding-top: 5px;
            font-size: 20px;
          }
        }

        .text-menu {
          display: none
        }

        .hamb-menu {
          display: inherit;
          cursor: pointer;
        }
      }
    }

    .header-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      .header-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        font-size: 22px;
        cursor: pointer;
        margin-bottom: 14px;

        .img-container {
          position: relative;
          overflow: hidden;
          height: 155px;
          border-radius: 5px;
          width: 90%;

          img {
            position: absolute;
            top: -50%;
            width: 100%;
          }
        }

      }
    }
  }
}